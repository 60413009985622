<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.market.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="name"
            :headerText="$t('models.market_plan.name')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="order_close_day"
            :headerText="$t('models.market_plan.order_close')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams"
            :template="cTemplate"
          ></e-column>
          <!-- <e-column
            field="order_close"
            :headerText="$t('models.market_plan.order_close')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue_="label"
            :dataSource_="scheduleWeek"
            foreignKeyField_="value"
          ></e-column> -->
          <e-column
            field="order_close_time"
            :headerText="$t('models.market_plan.order_close_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams1"
          ></e-column>
          <!-- <e-column
            field="producer_close"
            :headerText="$t('models.market_plan.producer_close')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="producer_close_time"
            :headerText="$t('models.market_plan.producer_close_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams2"
          ></e-column> -->
          <e-column
            field="offset_producer_close"
            :headerText="$t('models.market_plan.offset_producer_close')"
            type="number"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <!-- <e-column
            field="producer_delivery"
            :headerText="$t('models.market_plan.producer_delivery')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="producer_delivery_time"
            :headerText="$t('models.market_plan.producer_delivery_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams3"
          ></e-column> -->
          <e-column
            field="offset_producer_delivery"
            :headerText="$t('models.market_plan.offset_producer_delivery')"
            type="number"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <!-- <e-column
            field="order_delivery"
            :headerText="$t('models.market_plan.order_delivery')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="scheduleWeek"
            foreignKeyField="value"
          ></e-column>
          <e-column
            field="order_delivery_time"
            :headerText="$t('models.market_plan.order_delivery_time')"
            clipMode="EllipsisWithTooltip"
            :edit="dpParams4"
          ></e-column> -->
          <e-column
            field="offset_order_delivery"
            :headerText="$t('models.market_plan.offset_order_delivery')"
            type="number"
            clipMode="EllipsisWithTooltip"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { GetDataManager } from "../../../ds/index";
import TB from "../../../common/TB";
import { TimePicker } from "@syncfusion/ej2-calendars";
import { MultiSelectPlugin, MultiSelect } from "@syncfusion/ej2-vue-dropdowns";
Vue.use(MultiSelectPlugin);
let elem1;
let datePickerObj1;

let elem;
let multiSelectObj;

export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_market_planningdata", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: ["name", "id"],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],
        // reload_on_save:true,
      },
      // scheduleWeek: new DataManager([
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=MO", label: "Lunedì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=TU", label: "Martedì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=WE", label: "Mercoledì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=TH", label: "Giovedì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=FR", label: "Venerdì" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=SA", label: "Sabato" },
      //   { value: "RRULE:FREQ=WEEKLY;BYDAY=SU", label: "Domenica" },
      // ]),
      scheduleWeekS: [
        { value: "MO", label: "Lunedì" },
        { value: "TU", label: "Martedì" },
        { value: "WE", label: "Mercoledì" },
        { value: "TH", label: "Giovedì" },
        { value: "FR", label: "Venerdì" },
        { value: "SA", label: "Sabato" },
        { value: "SU", label: "Domenica" },
      ],
      cTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<span>{{s_list}}</span>`,
            data: function () {
              return {
                data: {},
                scheduleWeekS: [
                  { value: "MO", label: "Lunedì" },
                  { value: "TU", label: "Martedì" },
                  { value: "WE", label: "Mercoledì" },
                  { value: "TH", label: "Giovedì" },
                  { value: "FR", label: "Venerdì" },
                  { value: "SA", label: "Sabato" },
                  { value: "SU", label: "Domenica" },
                ],
              };
            },
            computed: {
              s_list: function () {
                let self = this;
                let values = this.scheduleWeekS.reduce(function (map, val) {
                  if (self.data.order_close_day.includes(val.value)) {
                    map.push(val.label);
                  }
                  return map;
                }, []);

                return values.join(", ");
              },
            },
          }),
        };
      },
      dpParams: {
        create: function () {
          elem = document.createElement("input");
          return elem;
        },
        read: () => {
          return multiSelectObj.value;
        },
        destroy: () => {
          multiSelectObj.destroy();
        },
        write: (args) => {
          var data = args.rowData.order_close_day;
          multiSelectObj = new MultiSelect({
            // value:
            //   typeof data === "string"
            //     ? args.rowData.order_close_day.split()
            //     : data,
            value: data,
            fields: { text: "label", value: "value" },
            dataSource: this.scheduleWeekS,
          });
          multiSelectObj.appendTo(elem);
        },
      },
      dpParams1: {
        create: function () {
          elem1 = document.createElement("input");
          return elem1;
        },
        read: () => {
          return (
            datePickerObj1.value.getHours() +
            ":" +
            datePickerObj1.value.getMinutes()
          );
        },
        destroy: () => {
          datePickerObj1.destroy();
        },
        write: (args) => {
          datePickerObj1 = new TimePicker({
            value: new Date("1970-01-01 " + args.rowData[args.column.field]),
            floatLabelType: "Never",
          });
          datePickerObj1.appendTo(elem1);
        },
      },
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
